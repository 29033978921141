<template>
  <div class="medalsOverview">
    <div class="flex flex-col h8:flex-row my-8 h8:my-16">
      <div class="w-full h8:w-1/3 mr-6 pb-6">
        <img class="" src="EQRC-bronze.svg" alt="" />
      </div>
      <p
        class="w-full h8:w-2/3 h8:pt-2 border-b border-gray-300 text-xs pb-4 k1:text-base text-gray-500"
      >
        An organisation will earn Bronze status once they have formulated and
        committed to their first step on their journey towards becoming
        quantum-ready.
      </p>
    </div>

    <div class="flex flex-col h8:flex-row my-8 h8:my-16">
      <div class="w-full h8:w-1/3 mr-6 pb-6">
        <img class="" src="EQRC-silver.svg" alt="" />
      </div>
      <p
        class="w-full h8:w-2/3 h8:pt-2 border-b border-gray-300 pb-4 text-xs k1:text-base text-gray-500"
      >
        Organisations that have made noteworthy progress in making changes to
        endorse multiple principles will obtain Silver status.
      </p>
    </div>

    <div class="flex flex-col h8:flex-row my-8 h8:my-16">
      <div class="w-full h8:w-1/3 mr-6 pb-6">
        <img class="" src="EQRC-gold.svg" alt="" />
      </div>

      <p
        class="w-full h8:w-2/3 h8:pt-2 border-b border-gray-300 pb-4 text-xs k1:text-base text-gray-500"
      >
        Gold status represents an exceptional level of excellence in endorsing
        all principles of quantum readiness, and serves as a role model for
        other organisations to follow. Gold status organisations will also be
        listed on the Quantum Flagship’s website at
        <a href="http://qt.eu" target="_blank" class="text-blue-600">qt.eu</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MedalsOverView",
};
</script>

<style scoped></style>
