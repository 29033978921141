<template>
  <div class="aboutMedals">
    <h2 class="heading1">
      About Medals
      <hr class="gradientLine" />
    </h2>

    <p>
      During Phase I (2023), organisations are granted quantum status
      immediately upon joining the Accord, as soon as they develop and submit an
      action plan to implement the six principles. The primary focus of Phase I
      is to collect input from participating organisations, which will be used
      to develop a roadmap and a collection of best practices for the effective
      implementation of the Accord's six principles. This status will be
      signalled through a medal that organisations can display on their website
      and documents.
    </p>
    <!--The problem with the width of the medal about component is this grid-->
    <div class="flex-col h8:flex-row flex my-8">
      <div class="w-full h8:w-1/3 mr-6 pb-6">
        <img class="" src="EQRC-quantum.svg" alt="" />
      </div>
      <p
        class="w-full h8:w-2/3 h8:pt-2 right grow border-b border-gray-300 text-xs k1:text-base pb-4 h6:pb-0 text-gray-500"
      >
        Every organisation that commits to the initiative and the principles
        receives the quantum-status level of acknowledgement.
      </p>
    </div>

    <p class="pb-4">
      During Phase II (2024), the focus will be on disseminating the best
      practices gathered in Phase I. Furthermore, organisations will be
      recognised for their level of commitment and progress in implementing the
      six principles through the awarding of gold, silver, or bronze medals.
      These medals will signify the organisation's quantum readiness status and
      can be displayed on their website and documents.
    </p>

    <MedalsOverView></MedalsOverView>
  </div>
</template>

<script>
import MedalsOverView from "@/components/MedalsOverview.vue";

export default {
  name: "AboutMedals.vue",
  components: { MedalsOverView },
};
</script>

<style scoped></style>
